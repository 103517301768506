* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(black, 0);
}

html {
    font-size: 62.5%;
}

body {
    position: relative;
    overflow-x: hidden;
    color: $base-color;
    font-family: $base-font;
    font-size: 1.6rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    background: $grey-10;

    &.Theme--Arkitekturgalan {
        background: $arkitekturgalan-bg;
    }
}

a {
    color: $base-color;
    text-decoration: none;
    transition: color $transition;

    &:hover {
        color: $base-color;
    }
}

sup {
    vertical-align: super;
    font-size: 1.4rem;
}

sub {
    vertical-align: sub;
    font-size: 1.4rem;
}

// Used for full page overlays
.overflow-hidden {
    overflow: hidden;
    position: relative;
}

.no-scroll {
    overflow: hidden;
}

// Stolen with pride from bootstrap
.sr-only {
    @extend %sr-only;
}
