$datepicker__background-color: $grey-10;
$datepicker__border-color: $grey-30;
$datepicker__highlighted-color: $purple-10;
$datepicker__muted-color: black;
$datepicker__selected-color: $primary-color;
$datepicker__text-color: $grey;
$datepicker__header-color: black;
$datepicker__border-radius: 0;
$datepicker__font-size: 1.1rem;
$datepicker__font-family: $base-font;
$datepicker__item-size: 2rem;
$datepicker__margin: 0.2rem;

@import '~react-datepicker/src/stylesheets/datepicker';

.react-datepicker {
    $root: &;

    letter-spacing: .3px;

    &-wrapper {
        display: block;
    }

    &-popper {
        z-index: 2;
    }

    &__navigation {
        top: 13px;
        border-width: 6px;
        height: 10px;
    }

    &__input-container {
        input {
            @extend %form__input;
        }
    }

    &__day {
        border-radius: 50%;

        &:hover {
            border-radius: 50%;
        }

        &:hover {
            background: $grey-10;
        }

        &--selected {
            font-weight: $bold;
            border-radius: 50%;
            background: $datepicker__selected-color;

            &:hover {
                background: $datepicker__selected-color;
            }
        }

        &--outside-month {
            opacity: .6;
        }
    }

    &__time-list-item {
        #{$root} & {
            margin: 0;

            &::before {
                display: none;
            }
        }
    }
}