div#cookiescript_injected {
    &,
    #cookiescript_wrapper {
        font-family: $base-font !important;
    }

    #cookiescript_wrapper {
        width: calc(100vw - 32px);
        max-width: 610px;
        padding: 16px;
        max-height: 90vh;
        overflow-x: hidden;

        @include media(SL) {
            padding: 24px;
        }

        @include media(M) {
            padding: 40px 32px 32px;
        }

        @include media(L) {
            padding: 64px 40px 40px;
            width: 60%;
            max-width: none;
        }
    }

    &.hascookiereport {
        #cookiescript_wrapper {
            @include media(S) {
                padding-bottom: 20px;
            }

            @include media(M) {
                padding-top: 32px;
                padding-bottom: 8px;
            }

            @include media(L) {
                padding-top: 40px;
                padding-bottom: 8px;
            }
        }
    }

    // Main title
    #cookiescript_header {
        font-size: 2rem;
        font-weight: $heavy;
    }

    #cookiescript_description {
        font-size: 1.2rem;

        a {
            font-size: 1.2rem;
        }
    }

    #cookiescript_buttons {
        flex-wrap: nowrap;
    }

    // Button for only accepting necessary
    #cookiescript_reject {
        color: $primary-color;
        border-color: $primary-color;
        background: white;

        &:hover {
            background: $purple-10;
        }
    }

    // Buttons for accepting all cookies or saving the selection
    #cookiescript_accept,
    #cookiescript_save {
        background: $primary-color;

        &:hover {
            background: $purple-60;
        }
    }

    // Buttons
    #cookiescript_reject,
    #cookiescript_accept,
    #cookiescript_save {
        width: auto;
        min-width: 0;
        font-size: 1.4rem;
        font-weight: $heavy;
        letter-spacing: 1px;
        text-transform: none;
        line-height: 3.4;
        transition: background $transition;
    }

    // Changing checkbox design
    .cookiescript_checkbox {
        &:hover {
            .mdc-checkbox {
                &__native-control {
                    &:not(:disabled) {
                        ~ .mdc-checkbox__background {
                            &::before {
                                background: $purple-10;
                                transform: scale(1);
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .mdc-checkbox {
        padding: 11px 13px;

        &__checkmark {
            width: 14px;
            height: 12px;
            margin: auto;
        }

        &__native-control {
            ~ .mdc-checkbox__background {
                &::before {
                    top: -12px;
                    left: -12px;
                }
            }

            &:enabled,
            &:disabled,
            &:enabled:checked,
            &[disabled]:checked,
            &[disabled]:indeterminate {
                ~ .mdc-checkbox__background {
                    border-width: 1px;
                    border-color: $primary-color;
                    border-radius: 0;
                    background-color: $primary-color;

                    .mdc-checkbox__checkmark {
                        color: white;
                    }

                    &::before {
                        z-index: -1;
                    }
                }
            }

            &[disabled]:checked,
            &[disabled]:indeterminate {
                ~ .mdc-checkbox__background {
                    border-color: $grey-20;
                    background-color: $grey-20;
                }
            }

            &:enabled:not(:checked):not(:indeterminate) {
                ~ .mdc-checkbox__background {
                    border-color: $primary-color;
                    background-color: transparent;
                }
            }

            &:focus {
                ~ .mdc-checkbox__background {
                    &::before {
                        opacity: 1;
                    }
                }
            }

            &:focus,
            &:checked,
            &:indeterminate {
                ~ .mdc-checkbox__background {
                    &::before {
                        background-color: $purple-20 !important;
                    }
                }
            }
        }
    }

    span.cookiescript_checkbox_text {
        font-size: 1.4rem;
        letter-spacing: .5px;
        text-transform: none;
    }

    #cookiescript_cookietablewrap {
        &:not(.cookiescript_hidden) {
            min-height: 300px;
        }
    }

    // For the tabs when active
    #cookiescript_maintabs {
        > div {
            color: black;
            font-weight: $heavy;
            font-size: 1.4rem;
            letter-spacing: .5px;
            text-transform: none;
            transition: background $transition;

            &.cookiescript_active {
                border: 1px solid $primary-color;
                border-bottom: none;
            }

            &:not(.cookiescript_active) {
                color: $primary-color;
                border-bottom: 1px solid $primary-color;
                background: $purple-10;

                &:hover {
                    background: $purple-20;
                }
            }
        }
    }

    #cookiescript_tabscontent {
        top: 0;
    }

    // For the categories
    #cookiescript_categories .cookiescript_active,
    #cookiescript_iab_type .cookiescript_active {
        background: $primary-color;
    }

    #cookiescript_categories,
    #cookiescript_iab_type {
        > div {
            color: $grey;
            font-size: 1.2rem;
            font-weight: normal;
            letter-spacing: .5px;
            border: 1px solid $grey-30;
            transition: border-color $transition, color $transition, background-color $transition;

            &.cookiescript_active {
                color: $primary-color;
                border-color: $primary-color;
                background: $purple-10;
            }

            &:not(.cookiescript_active) {
                &:hover {
                    border-color: $grey-35;
                }
            }
        }
    }

    // Scrollbar for report area
    #cookiescript_reportwrap::-webkit-scrollbar-thumb,
    #cookiescript_iabwrap::-webkit-scrollbar-thumb,
    #cookiescript_aboutwrap::-webkit-scrollbar-thumb {
        background-color: $primary-color;
    }

    // Cookie script text at bottom
    #cookiescript_reportdate {
        margin-top: 5px;
        line-height: 1.4;
    }

    // Links in cookie script text in bottom
    #cookiescript_readmore,
    #cookiescript_reportlink,
    #cookiescript_cookiescriptlink {
        color: $primary-color;

        &:hover {
            text-decoration: underline;
        }
    }

    #cookiescript_aboutwrap {
        padding-top: px;
    }

    #cookiescript_manage {
        color: $primary-color;
        font-size: 1.2rem;
        text-transform: none;
        opacity: 1;

        &:hover span {
            text-decoration: underline;
        }

        #cookiescript_manageicon {
            .cookiescript_gear {
                fill: $primary-color;
                transition: fill $transition;
            }
        }
    }
}

div#cookiescript_badge {
    display: flex !important;
    position: absolute;
    bottom: 220px;
    left: 16px;
    padding: 10px;
    margin-left: -13px;
    border-radius: 30px;
    background: transparent;
    box-shadow: none;
    opacity: 1 !important;
    transition: background-color $transition;

    // Hide when mobile menu is open
    .js-state-menumobile-open & {
        display: none !important;
    }

    &:hover {
        background: white;
    }

    @include media(SL) {
        left: calc((100vw - #{map-get($maxwidths, SL)}) / 2);
    }

    @include media(M) {
        left: calc((100vw - #{map-get($maxwidths, M)}) / 2);
    }

    @include media(ML) {
        bottom: 30px;
        left: calc((100vw - #{map-get($maxwidths, ML)}) / 2);
    }

    @include media(L) {
        left: calc((100vw - #{map-get($maxwidths, L)}) / 2);
    }

    @include media(XL) {
        left: calc((100vw - #{map-get($maxwidths, XL)}) / 2);
    }

    @include media(XXL) {
        left: calc((100vw - #{map-get($maxwidths, XXL)}) / 2);
    }

    #cookiescript_badgeimage {
        width: 26px;
        height: 26px;
        background: url('#{$assetsPath}img/cookie--purple.svg') no-repeat center center;
        background-size: contain;

        svg {
            display: none;
        }
    }

    #cookiescript_badgetext {
        display: block !important;
        max-width: 300px !important;
        padding-right: 0 !important;
        padding-left: 8px !important;
        color: $primary-color;
        font-size: 1.6rem;
        font-family: $base-font;
        font-weight: normal;
        text-transform: none;
        line-height: 1.6;
    }
}
