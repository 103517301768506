/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: https://www.linotype.com
*/

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=cbb1aedd-875e-4a35-822b-ed3f83836e54&fontids=1475800,1475806,1475812,1475818,6046129,6046141");
/*
    Fast font ids:
    1475800: Book
    1475806: Book Oblique
    1475812: Medium
    1475818: sMedium Oblique
    6046129: Heavy
    6046141: Heavy Oblique
*/

/* Make sure to add the below fonts being used as preloding in setup.php */

@font-face {
    font-family: 'Futura LT';
    src: url('#{$fontsPath}Futura/futura-lt-book.woff2') format('woff2'),
         url('#{$fontsPath}Futura/futura-lt-book.woff') format('woff');
    font-display: swap;
    font-style: none;
    font-weight: $regular;
    font-display: swap;
}

/*@font-face {
    font-family:'Futura LT';
    src:url('#{$fontsPath}Futura/futura-lt-book-oblique.woff2') format('woff2'),
        url('#{$fontsPath}Futura/futura-lt-book-oblique.woff') format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: $regular;
    font-display: swap;
}*/

@font-face {
    font-family: 'Futura LT';
    src: url('#{$fontsPath}Futura/futura-lt-medium.woff2') format('woff2'),
         url('#{$fontsPath}Futura/futura-lt-medium.woff') format('woff');
    font-style: none;
    font-weight: $medium;
    font-display: swap;
}

/*@font-face {
    font-family:'Futura LT';
    src:url('#{$fontsPath}Futura/futura-lt-medium-oblique.woff2') format('woff2'),
        url('#{$fontsPath}Futura/futura-lt-medium-oblique.woff') format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: $medium;
    font-display: swap;
}*/

@font-face {
    font-family: 'Futura LT';
    src: url('#{$fontsPath}Futura/futura-lt-heavy.woff2') format('woff2'),
         url('#{$fontsPath}Futura/futura-lt-heavy.woff') format('woff');
    font-display: swap;
    font-style: none;
    font-weight: $heavy;
    font-display: swap;
}

/*@font-face {
    font-family:'Futura LT';
    src:url('#{$fontsPath}Futura/futura-lt-heavy-oblique.woff2') format('woff2'),
        url('#{$fontsPath}Futura/futura-lt-heavy-oblique.woff') format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: $heavy;
    font-display: swap;
}*/
